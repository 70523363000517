import * as React from 'react'
import * as styles from '@/src/components/side/Auther.module.scss'

const Auther = () => {
  return (
    <div className={styles.box}>
      <div className={styles.image}>
        <img
          src="https://placehold.jp/495464/ffffff/100x100.png?text=%20"
          width="100"
          height="100"
          alt=""
        />
      </div>
      <p className={styles.name}>yuuki ite</p>
    </div>
  )
}

export default Auther
