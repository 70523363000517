import * as React from 'react'
import PropTypes from 'prop-types'
import DefaultLayout from '@/src/components/layout/Default'
import * as styles from '@/src/components/layout/Post.module.scss'
import { frontmatter } from '@/src/types/post'

const PostLayout = ({ children, pageContext }) => {
  return (
    <DefaultLayout>
      <div className={styles.post}>
        <h1>{pageContext.frontmatter.title}</h1>
        {children}
      </div>
    </DefaultLayout>
  )
}

PostLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  pageContext: PropTypes.shape({
    frontmatter
  })
}

export default PostLayout
