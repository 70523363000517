// extracted by mini-css-extract-plugin
export var inner = "Default-module--inner--2oVeG";
export var wrapper = "Default-module--wrapper--HwtzJ";
export var header = "Default-module--header--2F87w";
export var title = "Default-module--title--2hOmk";
export var section = "Default-module--section--2jhv4";
export var mainContent = "Default-module--mainContent--1GbHo";
export var side = "Default-module--side--3jQBN";
export var auther = "Default-module--auther--32S98";
export var footer = "Default-module--footer--2BO0Q";
export var copyright = "Default-module--copyright--208K1";