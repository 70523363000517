import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/src/components/layout/Post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Udemy の`}<a parentName="p" {...{
        "href": "https://www.udemy.com/course/understanding-typescript/"
      }}>{`Understanding TypeScript`}</a>{` を一通りやった。TypeScript でアプリケーションを書いていけそうな気になった。`}<br parentName="p"></br>{`
`}{`このコースとは関係ないが、create-react-app を typescript 対応で利用すると、型のエラーばんばん出てきて萎えそうだった。`}<br parentName="p"></br>{`
`}{`慣れるまではもっとゆるふわで行きたい。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      