import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as styles from '@/src/components/layout/Default.module.scss'
import Auther from '@/src/components/side/Auther'

const DefaultLayout = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div className={styles.inner}>
          <h2 className={styles.title}>
            <Link to="/">{site.siteMetadata.title}</Link>
          </h2>
        </div>
      </header>
      <section className={styles.section}>
        <div className={styles.inner}>
          <main className={styles.mainContent}>{children}</main>
          <aside className={styles.side}>
            <div className={styles.auther}>
              <Auther />
            </div>
          </aside>
        </div>
      </section>
      <footer className={styles.footer}>
        <div className={styles.inner}>
          <p className={styles.copyright}>{site.siteMetadata.title}</p>
        </div>
      </footer>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
}

export default DefaultLayout
