import PropTypes from 'prop-types'

export const frontmatter = PropTypes.shape({
  date: PropTypes.string,
  title: PropTypes.string
})

export const node = PropTypes.shape({
  frontmatter,
  excerpt: PropTypes.string,
  slug: PropTypes.string,
  id: PropTypes.string
})

export const data = PropTypes.shape({
  allMdx: PropTypes.shape({
    nodes: PropTypes.arrayOf(node)
  })
})
